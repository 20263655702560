import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

var environment = document.querySelector('meta[name="rails_env"]').content

window.Sentry = Sentry

if (environment != "development") {
  Sentry.init({
    dsn: "https://cb99446d611e4521b2160a5b79b511c6@o1401350.ingest.sentry.io/6748089",
    environment: environment,
    debug: true,
    release: "headcount-js@2.0.0",
    //integrations: [new BrowserTracing()],
    maxBreadcrumbs: 30,
    attachStacktrace: true,
    tracesSampleRate: 1.0,
  });
}

